<template>
  <div class="container">
     <div class="cash-wrap">
   <div class="cash-item">
    <div class="cash-name">总收益</div>
    <div class="cash-value">¥{{cashMap.total}}</div>
   </div>
    <div class="cash-item">
    <div class="cash-name">已到账收益</div>
    <div class="cash-value">¥{{cashMap.account}}</div>
   </div>
    <div class="cash-item">
    <div class="cash-name">未到账收益</div>
    <div class="cash-value">¥{{cashMap.unaccount}}</div>
   </div>
  </div>
     <el-form :inline="true" >
  <el-form-item label="收益类型">
    <el-select v-model="type" placeholder="选择收益类型" clearable>
      <el-option label="课程收益" :value="1"></el-option>
      <el-option label="会员卡收益" :value="3"></el-option>
       <el-option label="消费金收益" :value="4"></el-option>
         <el-option label="支出" :value="2"></el-option>
           <el-option label="其他收入" :value="5"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item>
     <el-button  type="primary" @click="getList(1)">搜索</el-button>
  </el-form-item>

 
</el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
       <template #type="{ row }">
            <div>
              {{ row.pm ? "收益" : "支出" }}
            </div>
          </template>
          <template #status="{ row }">
            <div>
             <el-tag type="success" size="small" v-if="row.status === 1">已到账</el-tag>
             <el-tag type="info" size="small" v-if="row.status === 2">未到账</el-tag>
             <el-tag type="warning" size="small" v-if="row.status === 3">已退款</el-tag>
            </div>
          </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        // { name: "机构", value: "mechanism_name" },
        { name: "金额(元)", value: "cash" },
        { name: "类型", value: "type", type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "说明", value: "remark" },
        { name: "分成时间", value: "create_time" },
        { name: "预计到账日", value: "account_day" },
      ],
      Total: 0,
      type:'',
      cooperator_id:JSON.parse(localStorage.getItem("managerInfo")).cooperator_id,
      cashMap:{
        total:0,
        account:0,
        unaccount:0
      }
    };
  },

  mounted() {
    if(this.cooperator_id){
        this.load()
    this.getCashByStatus()
    }
    
  },

  methods: {
    //金额
    getCashByStatus() {
      this.$axios({
        url: "/user/adminBonusLog/cashSumByStatus",
        params: {
          cooperator_id: this.cooperator_id,
          pm:true
        },
      }).then((res) => {
        res.data.data.forEach(item=>{
          if(item.status === 1){
            this.cashMap.total += item.cash
            this.cashMap.account = item.cash
          }
          if(item.status === 2){
              this.cashMap.total += item.cash
              this.cashMap.unaccount = item.cash
          }
        })
      });
    },
    load() {
      if (this.DataList.length == 0) {
        this.getList(1);
      }
    },
    userDetail(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
    //分成记录
    getList(v) {
        if(!this.cooperator_id){return}
      let page = v || 1;
      this.$axios({
        url: "/user/adminBonusLog/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          cooperator_id: this.cooperator_id,
          type:this.type || null
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.cash-wrap{
  display: flex;
  margin-bottom: 10px;
}
.cash-item{
  padding: 10px 20px;
  margin-right: 20px;
  background-color: rgba(243, 202, 140, 0.5);
  border-radius: 10px;
  min-width: 150px;
}
.cash-name{
    font-size: 14px;
}
.cash-value{
 font-size: 20px;
 margin-top: 10px;
}
</style>